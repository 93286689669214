import React, {useState} from "react";
import QrReader from "react-qr-reader";
import Modal from './components/Modal';
import "./index.css";
import axios from 'axios';

export default function App() {
  const [scannedData, setScannedData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [estado, setEstado] = useState('bueno');
  const [placa, setPlaca] = useState(null);
  const [ubicacion, setUbicacion] = useState('');
  const [scanError, setScanError] = useState('');

  let handleScan = data => {
    if (data) {
      // Dividir la cadena en dos partes usando split() para separar por espacios
      const partes = data.split(' ');

      // Crear el objeto con las propiedades "placa" y "elemento"
      const objeto = {
        placa: partes[0],
        elemento: partes.slice(1).join(' '), // Unir las partes restantes como una sola cadena
      };
      setScannedData(objeto);
      handleOpenModal()
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEstado('bueno'); // Limpiar estado después de cerrar el modal
    setPlaca(''); // Limpiar estado después de cerrar el modal
    setUbicacion(''); // Limpiar ubicación después de cerrar el modal
    setScannedData('')
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleEstadoChange = (e) => {
    setEstado(e.target.value);
  };

  const handleUbicacionChange = (e) => {
    setUbicacion(e.target.value);
  };

  const handleInputPlacaChange = (e) => {
    setPlaca(e.target.value);
  };

  const handleSave = () => {
    // Aquí puedes manejar la lógica para guardar estado y ubicación
    // Por ejemplo, puedes enviar estos datos a tu backend
    debugger
    sendDataToBackend({scannedData, estado, ubicacion, placa})
    console.log('Datos a guardar:', scannedData, estado, ubicacion);
  };

  const handleError = err => {
    console.error('Error al escanear:', err);
    setScanError('No se pudo escanear el código. Por favor, inténtalo de nuevo.');
  };

  const sendDataToBackend = scannedCode => {
    axios.post('/scan-data', scannedCode)
      .then(response => {
        console.log('Datos escaneados enviados al backend:', response.data);
        // Luego puedes cerrar el modal
        handleModalClose();
      })
      .catch(error => {
        console.error('Error al enviar datos escaneados:', error);
        alert(error?.response?.data?.message || 'Un error no capturado')
      });
  };

  return (
    <div>
      <button onClick={handleOpenModal}>Abrir Modal</button>
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{width: "100%"}}
        facingMode="environment"
      />
      {showModal && (
        <Modal
          show={showModal}
          handleClose={handleModalClose}
          handleEstadoChange={handleEstadoChange}
        >
          {scannedData.placa ? (
            <div>
              <h2>Placa: {scannedData.placa}</h2>
              <h2>Elemento: {scannedData.elemento}</h2>
            </div>
          ) : (
            <div>
              <label>
                Ingrese el número de placa:
                <input
                  type="text"
                  value={placa}
                  onChange={handleInputPlacaChange}
                />
              </label>
            </div>
          )}
          <label>
            Estado:
            <select value={estado} onChange={handleEstadoChange}>
              <option value="bueno">Bueno</option>
              <option value="inservible">Inservible</option>
            </select>
          </label>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label>
            Ubicación Actual:
            <input type="text" value={ubicacion} onChange={handleUbicacionChange}/>
          </label>
          &nbsp;&nbsp;
          <button onClick={handleSave}>Enviar</button>
        </Modal>
      )}
    </div>
  );
}
