import React from 'react';
import './Modal.css'; // Importa tu archivo de estilos CSS para el modal si es necesario

const Modal = ({ show, handleClose, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <button onClick={handleClose}>Cerrar</button>
        {children}
      </section>
    </div>
  );
};

export default Modal;
